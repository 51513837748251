<template>
    <div class="fm-layouts-default w-full">
        <Transition
            enter-active-class="animate__animated animate__fadeInUp"
            leave-active-class="animate__animated animate__fadeOutUp"
        >
            <div class="h-14 w-full" v-if="mobile.enabled && navEnabled">
                <nav
                    class="border-b-gray-150 fixed left-0 top-0 z-30 flex h-14 w-full items-center justify-between border-b bg-white"
                >
                    <ElButton icon="expand" text @click="openMobileNav">应用菜单</ElButton>
                    <ElButton text @click="logout">
                        <Icon icon="icon-bg-quit" class="el-icon--left" size="1" />
                        退出登录
                    </ElButton>
                </nav>
            </div>
        </Transition>
        <div class="fm-layouts-default__article flex w-full" :mobile="mobile.enabled">
            <Transition
                enter-active-class="animate__animated animate__fadeInLeft"
                leave-active-class="animate__animated animate__fadeOutLeft"
            >
                <nav
                    class="fixed left-0 top-0 z-50 flex h-full w-60 shrink-0 flex-col items-center bg-primary-light py-6 md:relative md:py-10"
                    v-if="navEnabled"
                    v-show="mobile.navShow || !mobile.enabled"
                    ref="navRef"
                >
                    <img
                        @click="
                            router.push('/info/base');
                            closeMobileNav();
                        "
                        src="/assets/img/logo.svg"
                        alt="logo"
                        class="h-10 cursor-pointer transition-transform hover:scale-110"
                    />
                    <!-- 基本信息 -->
                    <div
                        :class="{
                            'group relative flex flex-col items-center gap-4 py-8 md:py-14': true,
                            'cursor-pointer': !infoBaseActive,
                        }"
                        @click="
                            router.push('/info/base');
                            closeMobileNav();
                        "
                    >
                        <ElTooltip content="点击编辑基本信息" :disabled="infoBaseActive" placement="top">
                            <ElAvatar
                                :src="user.avatar"
                                v-if="user.avatar"
                                :size="mobile.enabled ? 90 : 100"
                                :class="{
                                    'fm-layouts-default__avatar border-2 border-solid border-primary-light': true,
                                    'group-hover:border-primary': !infoBaseActive,
                                }"
                            >
                                <img src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png" />
                            </ElAvatar>
                            <ElAvatar v-else :icon="UserFilled" :size="100" class="fm-layouts-default__avatar" />
                        </ElTooltip>
                        <h1 class="relative text-xl font-bold">
                            <span>{{ user.uname || "姓名未录入" }}</span>
                            <Icon
                                :class="{
                                    'absolute -right-2 top-1/2 hidden -translate-y-1/2 translate-x-full text-primary': true,
                                    'group-hover:block': !infoBaseActive,
                                }"
                                icon="icon-edits-full"
                                size="1"
                            />
                        </h1>
                    </div>
                    <!-- 应用列表 -->
                    <ul
                        class="fm-layouts-default__app mt-2 flex h-px grow flex-col items-center justify-center gap-7 md:mt-5 md:justify-start md:gap-10"
                    >
                        <li
                            :current="currentApp === app.id"
                            class="relative flex items-center"
                            v-for="app in apps"
                            :key="app.name"
                            @click="
                                openApp(app);
                                closeMobileNav();
                            "
                        >
                            <label class="cursor-pointer text-lg text-primary-light-text hover:text-primary">{{
                                app.name
                            }}</label>
                            <!-- <span v-if="app.badge" class="relative w-0">
                            <ElBadge :value="app.badge" class="absolute left-2 top-1" :max="99" />
                        </span> -->
                        </li>
                    </ul>
                    <!-- 底部按钮 -->
                    <div class="mt-8 flex flex-col">
                        <ElPopover popper-class="fm-layouts-default__nav-kefu" placement="top">
                            <div
                                class="flex w-52 flex-col items-center gap-3 rounded-xl border-4 border-solid border-primary p-4"
                            >
                                <img src="/assets/img/kefu-online.jpg" alt="客服二维码" />
                                <span class="text-primary">微信扫码直接联系客服</span>
                            </div>
                            <template #reference>
                                <button
                                    class="flex items-center gap-2 rounded-md px-4 py-2 text-yellow-600 hover:bg-[#EBDEBE]"
                                >
                                    <Icon icon="icon-kefu-full" size="1.8" />
                                    <span class="text-lg">我的客服</span>
                                </button>
                            </template>
                        </ElPopover>
                        <button class="flex items-center gap-2 rounded-md px-4 py-2 hover:bg-[#EBDEBE]" @click="logout">
                            <Icon icon="icon-bg-quit" size="1.8" />
                            <span class="text-lg">退出登录</span>
                        </button>
                    </div>
                </nav>
            </Transition>
            <Transition
                enter-active-class="animate__animated animate__fadeIn"
                leave-active-class="animate__animated animate__fadeOut"
            >
                <div
                    v-show="mobile.navShow && mobile.enabled"
                    class="fixed left-0 top-0 z-40 h-screen w-screen bg-black bg-opacity-30"
                ></div>
            </Transition>
            <!-- 内容区 -->
            <article class="w-px grow">
                <slot></slot>
            </article>
        </div>
        <div v-if="mobile.enabled && !navEnabled" class="mb-2 flex justify-center text-gray-300" @click="openFooter">
            ↓滚动查看网站信息↓
        </div>
        <!-- 底部栏 -->
        <ElTooltip content="点击查看网站信息" :disabled="y >= 10">
            <FooterContract
                @click="openFooter"
                :class="{ 'cursor-pointer bg-primary hover:bg-primary-deep': y < 10, 'bg-primary-deep': y >= 10 }"
                ref="footerRef"
                v-if="!mobile.enabled || !navEnabled"
            />
        </ElTooltip>
    </div>
</template>

<script setup lang="ts">
import type { ErrorExtend } from "#imports";
import { UserFilled } from "@element-plus/icons-vue";
import { HttpStatusCode } from "axios";
import type { AppDocument } from "~/types/app.type";

const navEnabled = ref(false);

//////////////////////////////////////////////////////////////////////////////////////////////////// 应用逻辑
const router = useRouter();
const route = useRoute();
const apps: Ref<AppDocument[]> = ref([]);
const { initApps } = useApp();
function refreshApps() {
    if (navEnabled.value) {
        initApps()
            .then((resApps: AppDocument[]) => {
                navEnabled.value = true;
                apps.value = resApps;
            })
            .catch((err: ErrorExtend) => {
                if (err.code !== HttpStatusCode.Unauthorized) {
                    ElNotification.error({ title: "获取应用列表失败", message: err.message, duration: 5000 });
                }
            });
    }
}
/** 当前导航索引 */
const currentApp: Ref<string | undefined> = computed(() => {
    return apps.value.find((app: AppDocument) => {
        return new RegExp(`^${app.path}`).test(route.path);
    })?.id;
});

/** 打开应用 */
function openApp(app: AppDocument) {
    router.push(app.functions[0].route);
}

//////////////////////////////////////////////////////////////////////////////////////////////////// 用户信息
const user = useUser();

function refreshInfo() {
    user.initUser();
}

watch(
    () => ({ token: user.token }),
    () => {
        if (user.token) {
            navEnabled.value = true;
            refreshApps();
            refreshInfo();
        } else {
            navEnabled.value = false;
        }
    },
    { immediate: true },
);
// 是否在基本信息页
const infoBaseActive = computed(() => {
    return route.path === "/info/base";
});

//////////////////////////////////////////////////////////////////////////////////////////////////// 登录逻辑
function logout() {
    ElMessageBox.confirm("确定要退出登录吗？", "退出登录", {
        type: "warning",
        confirmButtonText: "退出登录",
        cancelButtonText: "取消",
    }).then(() => {
        $axios
            .post("/auth/logout")
            .catch((err: ErrorExtend) => {
                ElNotification.error({ title: "退出登录失败", message: err.message, duration: 5000 });
            })
            .finally(() => {
                user.clearUser();
                router.push("/login");
                navEnabled.value = false;
            });
    });
}

//////////////////////////////////////////////////////////////////////////////////////////////////// 底部栏逻辑
const { y } = useScroll(window);
const footerRef = ref();
// 点击底部栏外部滚动到顶部
onClickOutside(
    footerRef,
    () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    },
    {
        ignore: [".el-popper"],
    },
);
const footer = reactive({
    kefuVisible: false,
});
function openFooter() {
    window.scrollTo({ top: 200, behavior: "smooth" });
}

//////////////////////////////////////////////////////////////////////////////////////////////////// 移动端
const mobile = reactive({
    /** 导航是否显示 */
    navShow: false,
    enabled: false,
});

const navRef = ref();

function closeMobileNav() {
    mobile.navShow = false;
}

onClickOutside(navRef, closeMobileNav);

function openMobileNav() {
    mobile.navShow = true;
}

function resize() {
    mobile.enabled = window.innerWidth <= 768;
}
onMounted(() => {
    nextTick(() => {
        window.addEventListener("resize", resize);
        resize();
    });
});
onUnmounted(() => {
    window.removeEventListener("resize", resize);
});
</script>

<style lang="scss" scoped>
.fm-layouts-default {
    .animate__animated {
        animation-duration: 0.2s;
    }
    .fm-layouts-default__avatar {
        :deep(i) {
            font-size: 3.5714rem;
        }
    }
    .fm-layouts-default__app {
        li[current="true"] label {
            @apply font-bold text-slate-800;
        }
        li[current="true"]::after {
            content: "";
            @apply absolute left-1/2 top-7 h-2 w-6 -translate-x-1/2 rounded-2xl bg-primary;
        }
    }
    .fm-layouts-default__article {
        height: calc(100vh - 0.8571rem);
    }
    .fm-layouts-default__article[mobile="true"] {
        height: calc(100vh - 2.5rem);
    }
}
</style>

<style lang="scss">
.fm-layouts-default__nav-kefu {
    padding: 0 !important;
    width: auto !important;
    border-radius: 0.8rem !important;
    border: none !important;
}
</style>
